import React from 'react';
import Head from 'next/head';
import EnplusLayout from '@studionand/depi/src/components/enplus/Layout';
import Header from '@studionand/depi/src/components/enplus/Header';
import StatsSection from '@studionand/depi/src/components/enplus/StatsSection';
import InternalSection from '@studionand/depi/src/components/enplus/InternalSection';
import BenefitContent from '@studionand/depi/src/components/enplus/pellets/BenefitContent';
import FeatureSection from '@studionand/depi/src/components/enplus/FeatureSection';
import ButtonSection from '@studionand/depi/src/components/enplus/ButtonSection';

const EnplusPelletsPage = props => {
  return (
    <EnplusLayout context="pellets" hideNavPhotoEdge>
      <Head>
        <title>ENplus Pellets - Startseite</title>
      </Head>
      <Header />
      <StatsSection context="pellets" cardContent={BenefitContent} noMargin />
      <ButtonSection white />
      <InternalSection />
      <FeatureSection />
    </EnplusLayout>
  );
};

EnplusPelletsPage.getInitialProps = async ({ dataStore, uiStore }) => {
  await dataStore.pages.fetchFeatures(uiStore.context);
  return {};
};

export default EnplusPelletsPage;
